import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  
  companyInfos = {
    name: 'otoperde',
    contact: {
      address: 'Batkan Oto Dizayn San.ve Tic.Ltd.Şti Alt Sanayi Tepecik Bulvarı No:54 09010 AYDIN',
      phones: [
        {
          title: 'İletişim',
          no: '0 256 211 38 38'
        },
        {
          title: 'wp',
          no: ' | 0 532 066 02 63'
        }
      ],
      fullName: '',      
      fax: '',
      email: 'info@otoperde.com'
    },
    social: [
      {
        type: 'twitter',
        url: 'https://twitter.com/mobimbooffical',
        icon: 'fab fa-twitter'
      },
      // {
      //   type: 'facebook',
      //   url: 'https://www.facebook.com/otoaksesuarci',
      //   icon: 'fab fa-facebook-f'
      // },
      {
        type: 'youtube',
        url: 'https://www.youtube.com/channel/UCUOdVDKHQlgF-9qvGd5N8zQ',
        icon: 'fab fa-youtube'
      },
      {
        type: 'instagram',
        url: 'https://www.instagram.com/otoaksesuarcicom',
        icon: 'fab fa-instagram'
      }
    ]
  }
  theme = {
    type: 'shop', // shop - gallery
    title: 'otoperde',
    logo: {
      selected: 'image', // text - image
      text: 'otoperde',
      headerLogo: 'assets/images/logo-1.png',
      footerLogo: 'assets/images/logo-1.png'
    },
    favicon: '',
    maintenance: {
      active: false,
      leftImage: 'test'
    },
    showNoStock: 1,
    menuText:'otoperde',
  }
  app = {
    status:false, // eğer app yoksa false yapılacak gözükmemesi için
    googleUrl:'https://play.google.com/store/apps/details?id=com.glopark.mobimbo&hl=tr',
    appStoreUrl:'https://apps.apple.com/us/app/mobimbo/id1509691526'
  }

  constructor() { }
  // _theme-variables.scss dosyasında $accent-color == menü bg rengi burdan değişiyor ve button renkleri vs.

}
