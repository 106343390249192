<div class="site-footer__widget footer-contacts">
    <h5 class="footer-contacts__title">İletişim</h5>

    <div class="footer-contacts__text">
        {{settings.companyInfos.contact.fullName}}
    </div>

    <ul class="footer-contacts__contacts">
        <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ settings.companyInfos.contact.address }}</li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> {{ settings.companyInfos.contact.email }}</li>
        <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> <ng-container *ngFor="let item of settings.companyInfos.contact.phones">{{ item['no']}}</ng-container></li>
        <!-- <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li> -->
    </ul>
</div>
