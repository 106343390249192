import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Product } from '../../interfaces/product';
import { FormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { RootService } from '../../services/root.service';
import { Angulartics2 } from 'angulartics2';
import { ProductsService } from '../../services/products.service';
import { ToastrService } from 'ngx-toastr';
import { Cart2Service } from '../../services/cart2.service';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {
    @Input() layout: ProductLayout;

    @Input() product: Product;

    quantity: FormControl = new FormControl(1);

    attributes;
    selectedAtts = []
    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    variationNo = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private cartService: Cart2Service,
        private wishlist: WishlistService,
        private compare: CompareService,
        public root: RootService,
        private angulartics2: Angulartics2,
        private productService: ProductsService,
        private toastr:ToastrService,
        private metaService: Meta,
        private router: Router
    ) {
    }

    ngOnInit() { 
        this.attributes = this.productService.getProductAttributes(this.product['variations'])
        if(this.attributes.length == 0){
            this.variationNo = true
            this.product['variations'].forEach(element => {
                this.product.price = element.salePrice
                this.product.compareAtPrice = element.displayPrice
                this.product.id = element.id
                this.product.sku = element.sellerStockCode
                this.product['ItemGuid'] = element.ItemGuid
                this.product['quantity'] = element.quantity
            });
        }
        this.selectedAtts.push(this.attributes[0]?.name)
        this.angulartics2.eventTrack.next({
            action: 'ViewContent',
            properties: { 
                content_type: 'product', 
                content_ids: this.product?.name + '_01',
                value:this.product.price,
                currency:'TRY',
                content_category:this.product?.brand?.name
              },
        });
    }

    addToCart() {
        if(this.lastAttSelected){
            this.checkPrice()
            this.angulartics2.eventTrack.next({
                action: 'AddToCart',
                properties: { 
                    content_type: 'product', 
                    content_ids: this.product?.name + '_01',
                    value:this.product.price,
                    currency:'TRY',
                    content_category:this.product?.brand?.name
                  },
            });
            
            if (!this.addingToCart && this.product && this.quantity.value > 0) {
                this.addingToCart = true;
                console.log('3')
                this.cartService.addCart(this.product).subscribe({complete:()=>this.addingToCart = false})
                this.cart.add(this.product, this.quantity.value).subscribe({ complete: () => this.addingToCart = false });
            }
        }
        else if(this.variationNo){
            if (!this.addingToCart && this.product && this.quantity.value > 0) {
                this.addingToCart = true;
                console.log('4')
                // this.cartService.addCart(this.product).subscribe({complete:()=>this.addingToCart = false})
                this.cart.add(this.product, this.quantity.value).subscribe({ complete: () => this.addingToCart = false });
            }
        }
        else{
            this.toastr.error('Tüm özellikler seçilmedi')
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist.add(this.product).subscribe({ complete: () => this.addingToWishlist = false });
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({ complete: () => this.addingToCompare = false });
        }
    }

    async changeVariantSize(patt, pav) {
        await this.resetAtt(patt.name)
        pav.isSelected = true
        this.checkPrice()
        let attName = ''
        this.product['variations'].forEach(element => {
            element.stockItemAttribute.find(pa => {
                if (pa.name == patt.name && pa.value == pav.value) {
                    for (let i = 0; i < element.stockItemAttribute.length; i++) {

                        if (this.selectedAtts.includes(element.stockItemAttribute[i].name)) {
                            continue;
                        }
                        else {
                            let attribue = this.attributes.find(x => { return x.name == element.stockItemAttribute[i].name })
                            attribue.isOpen = true
                            attribue.values.find(y => { return y.value == element.stockItemAttribute[i].value }).isOpen = true
                            attName = element.stockItemAttribute[i].name
                            break
                        }

                    }
                }
            })
            // if (att) {
            //     if (element?.id == att?.stockItemId && pav?.value == element?.stockItemAttribute[1]?.value) {
            //         this.product['displayPrice'] = element.displayPrice;
            //         //   this.product['salePrice'] = element.salePrice;
            //     }
            // }
        });
        if (attName != '') {
            this.selectedAtts.push(attName)
        }
    }


    async resetAtt(attName) {
        let index = this.selectedAtts.indexOf(attName)
        index++
        this.attributes.find(x => { return x.name == attName }).values.forEach(element => {
            element.isSelected = false
        });


        for (index; index < this.selectedAtts.length; index++) {
            let attribute = this.attributes.find(x => { return x.name == this.selectedAtts[index] })
            attribute.isOpen = false
            attribute['values'].forEach(element => {
                element.isOpen = false
                element.isSelected = false
            });
        }
        for (let i = this.selectedAtts.indexOf(attName); i != this.selectedAtts.length - 1;) {
            this.selectedAtts.pop()
        }
    }


    lastAttSelected = false
    checkPrice() {
        this.lastAttSelected = false
        this.attributes[this.attributes.length-1].values.forEach(element => {
            if (element.isSelected) {
                this.lastAttSelected = true
                this.product['variations'].forEach(element => {
                    for (let i = 0; i < element.stockItemAttribute.length; i++) {
                        if (this.attributes.find(x => { return x.name == element.stockItemAttribute[i].name }).
                            values?.find(item => { return element.stockItemAttribute[i].value == item.value }).isSelected == false) break;
                        else if(i == element.stockItemAttribute.length-1) {
                            if(element.stockItemImage.length > 0){
                                this.product.images = [];
                                element.stockItemImage.forEach(x => {
                                    this.product.images.push(x.url)
                                });
                            }
                            else{
                            }
                            this.product.price = element.salePrice
                            this.product.compareAtPrice = element.displayPrice
                            this.product.id = element.id
                            this.product.sku = element.sellerStockCode
                            this.product['ItemGuid'] = element.ItemGuid
                            this.product['quantity'] = element.quantity
                         }
    
                    }
                });
            }
        });
    }


}
