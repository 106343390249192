import { /*LOCALE_ID, */NgModule } from '@angular/core';
// import { registerLocaleData } from '@angular/common';
// import localeIt from '@angular/common/locales/it';
//
// registerLocaleData(localeIt, 'it');

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';

// modules
import { AppRoutingModule } from './app-routing.module';
import { BlocksModule } from './modules/blocks/blocks.module';
import { FooterModule } from './modules/footer/footer.module';
import { HeaderModule } from './modules/header/header.module';
import { MobileModule } from './modules/mobile/mobile.module';
import { SharedModule } from './shared/shared.module';
import { WidgetsModule } from './modules/widgets/widgets.module';

// components
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';

// pages
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageHomeTwoComponent } from './pages/page-home-two/page-home-two.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageOffcanvasCartComponent } from './pages/page-offcanvas-cart/page-offcanvas-cart.component';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Facebook } from 'angulartics2/facebook'
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';


const cookieConfig:NgcCookieConsentConfig = 
{
  cookie: {
    domain: "mobimbo.com"
  },
  position: "bottom",
  theme: "edgeless",
  palette: {
    popup: {
      background: "#0b5fb5",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#6ea5ed",
      text: "#ffffff",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "Sitemizden en iyi şekilde faydalanabilmeniz için çerezler kullanılmaktadır. Bu siteye giriş yaparak çerez kullanımını kabul etmiş sayılıyorsunuz.",
    dismiss: "Tamam",
    deny: "Çerezleri reddet",
    link: "Daha fazla bilgi",
    href: "https://mobimbo.com",
    policy: "Çerez politikası"
  }
}


@NgModule({
    declarations: [
        // components
        AppComponent,
        RootComponent,
        // pages
        PageHomeOneComponent,
        PageHomeTwoComponent,
        PageNotFoundComponent,
        PageOffcanvasCartComponent
    ],
    imports: [
        // modules (angular)
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        NgcCookieConsentModule.forRoot(cookieConfig),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        // modules (third-party)
        CarouselModule,
        ToastrModule.forRoot(),
        // modules
        AppRoutingModule,
        BlocksModule,
        FooterModule,
        HeaderModule,
        MobileModule,
        SharedModule,
        CommonModule,
        WidgetsModule,
        Angulartics2Module.forRoot(),
        SocialLoginModule
    ],
    providers: [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '749612949285-tlg9638qootov4kf4nag9puq1djv9oof.apps.googleusercontent.com'
              )
            }
          ]
        } as SocialAuthServiceConfig,
      } 
        // { provide: LOCALE_ID, useValue: 'it' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
