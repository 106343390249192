import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from './account.service';
import { Constants } from  '../constants';
import { Product } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class Cart2Service {
  private isCartEmpty:boolean;
  cart;
  cartSummary;
  favariteIconFlash = false;
  cartItemCount = new BehaviorSubject(0);
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService
  ) {
    this.isCartEmpty = true
  }

  addCart(product,quan?): Observable<any> {
    console.log(quan)
    let productx = {}
    productx['contactId'] = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    productx['firmaGuid'] = Constants.guid;
    productx['quantity'] = quan
    productx['uuid'] = localStorage.getItem('uuid');
    productx['itemGuid'] = product['ItemGuid']
    productx['SepetGuid'] = product['SepetGuid']
    // if(!productx['quantity']){SepetGuid
    //   productx = null
    // }
    
    try {
      // product.ContactId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
      // product.FirmaGuid = Constants.guid;
      // product.uuid = localStorage.getItem('uuid');
      // product.ItemGuid = '7a60e892-e40a-40d2-8e2d-b44cac669a6b'
     

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
          'Content-Type': 'application/json'
        })
      }
      return this.http.post(`${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/insert-or-update`, productx, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
            return res;
          } else {
            return res;
          }
        }))
    } catch (error) {}
  }

  getCart(favorite?, couponCode?, kuponisle?): Observable<any>  {
    let uuid = localStorage.getItem('uuid');
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
        'Content-Type': 'application/json'
      })
    }
    let url = `${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/list/${accountId}?uuid=${uuid}`;

    if (favorite) { url += `&favori=${favorite}` }
    if (couponCode) { url += `&kupons=${couponCode}` }
    if (kuponisle) { url += `&kuponisle=true` } else { url +="&kuponisle=false" }

    return this.http.get(url, httpOptions)
    .pipe(map(res => {
      if (res['result']['status']) {
        this.cart = [res['data']];
        let items = []
        this.cart.forEach(element => {
          let x = element
          x.forEach(itemm => {         
            let item = {} as Product
            item.name = itemm.ProductName
            item.id = itemm.ContactId
            item.brand = itemm.marka
            item.attributes = []
            item.images = [itemm.ResimUrl]
            item.price = itemm.SalePrice
            item.compareAtPrice = itemm.Price
            item.slug = itemm.HandleUrl
            item['description'] = itemm.description
            item.sku = itemm.productSellerCode
            item['tags'] = itemm.etiketler
            item['variations'] = itemm.stockItem
            item['ItemGuid'] = itemm.ItemGuid
            item['Quantity'] = itemm.Quantity
            item['SepetGuid'] = itemm.SepetGuid

  
            items.push(item)
          });
      });
          return items;
        } else {
          this.cart = [];
          return res;
        }
      }))
  }

  getCartSumamry(favorite?) {
    let uuid = localStorage.getItem('uuid');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
        'Content-Type': 'application/json'
      })
    }
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    let url = `${Constants.b2cApiUrl}/sepet/ozet/${accountId}?uuid=${uuid}`;
    if (favorite) { url += `&favori=${favorite}` }
    return this.http.get(url, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          this.cartSummary = res['data'];
          return res;
        } else {
          this.cart = {};
          return res;
        }
      }))
  }

  deleteRow(guid) {
    let uuid = localStorage.getItem('uuid');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
        'Content-Type': 'application/json'
      })
    }
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    return this.http.post(`${Constants.b2cApiUrl}/sepet/delete/${accountId}?uuid=${uuid}`, [guid], httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          return res;
        } else {
          return res;
        }
      }))
  }

  changeCartItemStatus(row, status): Observable<any> {
    row.Favori = status;
    row.ContactId = this.accountService.getAccount.id;
    row.uuid = localStorage.getItem('uuid');
    row.FirmaGuid = Constants.guid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(`${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/insert-or-update`, row, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          return res;
        } else {
          return res;
        }
      }))
  }
}
